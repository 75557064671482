<script>
import Calendar from 'primevue/calendar';
import moment from 'moment/moment';
import DropdownMenuAk from '@components/v2/general/DropdownMenuAk.vue';

export default {
  components: {DropdownMenuAk, Calendar},
  inject: {
    pSubmitted: {default: null},
    pDisabled: {default: null},
  },
  props: {
    label: {
      type: String,
      required: false,
      default: undefined,
    },
    modelValue: {
      required: true,
    },
    direction: {
      type: String,
      required: false,
      default: 'center',
      validator(value) {
        return ['center', 'left', 'right'].includes(value);
      },
    },
  },
  emits: ['update:modelValue', 'search'],
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    labelDisplay() {
      if (this.currentValue && this.currentValue.length === 2 && this.currentValue[1] != undefined) {
        return (
          moment(this.currentValue[0]).format('DD/MM/yyy') + ' - ' + moment(this.currentValue[1]).format('DD/MM/yyy')
        );
      }
      return this.label;
    },
    hasValue() {
      return this.currentValue && this.currentValue.length === 2 && this.currentValue[1] != undefined;
    },
  },
  methods: {
    dateSelect(date) {
      this.$nextTick(() => {
        if (this.hasValue) {
          this.$emit('search');
          this.$refs.dropdown.hide();
        }
      });
    },
    dateReset() {
      this.currentValue = undefined;
      this.$emit('search');
      this.$refs.dropdown.hide();
    },
    scrollToTop() {
      this.$nextTick(() => {
        if (this.$refs.scroll) this.$refs.scroll.$el.scrollTop = 0;
      });
    },
  },
};
</script>

<template>
  <dropdown-menu-ak
    @opened="scrollToTop"
    ref="dropdown"
    class="dropdown-filter dropdown-xl"
    overlay
    :closeOnClickOutside="false"
    :classInside="[
      'p-monthpicker-month',
      'p-datepicker-month',
      'p-datepicker-year',
      'p-datepicker-prev',
      'p-datepicker-next',
      'p-yearpicker-year',
    ]"
    :closeOnClickOutsideAk="true"
    :direction="direction"
    mode="click">
    <template #trigger>
      <div
        class="flex items-center gap-1 py-2.5 px-1.5 hover:cursor-pointer hover:text-primary"
        :class="[hasValue ? 'text-primary' : 'text-gray']">
        <span class="flex-1 font-inter font-medium text-sm">{{ labelDisplay }}</span>
        <i class="ga-icon ga-chevron_down icon-rotate" />
      </div>
    </template>
    <template #body>
      <perfect-scrollbar ref="scroll" class="h-full">
        <Calendar
          ref="calendar"
          v-model="currentValue"
          inline
          class="w-full"
          @dateSelect="dateSelect"
          selectionMode="range"
          :manualInput="false"></Calendar>

        <hr />
        <div
          class="flex gap-2 items-center text-gray justify-center pt-4 hover:text-primary hover:cursor-pointer"
          @click="dateReset">
          <i class="ga-icon ga-refresh text-3xl" />
          <span class="font-medium text-base">Réinitialiser</span>
        </div>
      </perfect-scrollbar>
    </template>
  </dropdown-menu-ak>
</template>
