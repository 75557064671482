<script>
import {Icon} from '@iconify/vue';
import {required} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

/* COMPOSANTS */
import AkInputText from '@components/input/AkInputText';
import AkCheckbox from '@components/input/AkCheckbox';
import AkDialog from '@components/general/AkDialog';
import GaView from '@components/layout/GaView';

/* MIXINS */
import randomRef from '@mixins/randomRef';
import utilsMixin from '@mixins/utilsMixin';

/* SERVICES */
import itemLabelService from '@services/itemLabelService';

export default {
  components: {
    Icon,
    AkCheckbox,
    AkDialog,
    AkInputText,
    GaView,
  },
  mixins: [randomRef, utilsMixin],
  setup() {
    return {v$: useVuelidate()};
  },
  metaInfo() {
    return {
      title: 'settings.origin_marketing',
    };
  },
  data() {
    return {
      loading: true,
      list: [],
      item: {
        type: 'ORIGIN_MARKETING',
        enabled: true,
        label: '',
      },
    };
  },
  validations() {
    return {
      item: {
        label: {required},
      },
    };
  },
  mounted() {
    itemLabelService.itemLabels({type: 'ORIGIN_MARKETING'}).then((data) => {
      this.list = data;
      this.loading = false;
    });
  },
  methods: {
    create() {
      this.v$.$touch();
      if (this.v$.$error) return;

      this.getRef().showTotalLoader();
      itemLabelService
        .create(this.item)
        .then((data) => {
          this.getRef().success(this.$t('item_label.purchase_type_added'));
          this.list.push(data);
        })
        .finally(() => {
          this.getRef().hideLoader();
          this.$refs.dialogCreate.hide();
        });
    },
    update() {
      this.v$.$touch();
      if (this.v$.$error) return;

      this.getRef().showTotalLoader();
      itemLabelService
        .update(this.item)
        .then((data) => {
          this.replaceInListById(this.list, data);
          this.getRef().success(this.$t('item_label.purchase_type_updated'));
        })
        .finally(() => {
          this.getRef().hideLoader();
          this.$refs.dialogEdit.hide();
        });
    },
    delete() {
      itemLabelService
        .delete(this.item)
        .then((data) => {
          this.removeInListById(this.list, data.id);
          this.getRef().success(this.item.label + ' a bien été supprimé');
        })
        .catch((e) => {
          let content = 'Une erreur est survenue durant la suppression';
          if (e.response) {
            content = 'Une erreur est survenue durant la suppression : ' + e.response.data.error;
          }
          this.getRef().error(content);
        });
    },

    openCreateDialog() {
      this.item = {
        type: 'ORIGIN_MARKETING',
        enabled: true,
        label: '',
      };
      this.$refs.dialogCreate.show();
    },
    openEditDialog(item) {
      this.item = {...item};
      this.$refs.dialogEdit.show();
    },
    openDeleteDialog(item) {
      this.item = item;
      this.$refs.dialogDelete.show();
    },
    reorder(event) {
      const item = this.list[event.dragIndex];
      this.loading = true;
      itemLabelService.reorderPosition(item, event.dropIndex - event.dragIndex).then(() => {
        this.reorderInListByIdAndDelta(this.list, item.id, event.dropIndex - event.dragIndex);
        this.loading = false;
      });
    },
  },
};
</script>

<template>
  <GaView :ref="ref" :title="$t('settings.origin_marketing')">
    <template #action>
      <div class="d-flex justify-content-end">
        <button class="btn btn-primary float-right" @click="openCreateDialog()">
          <i class="gst-plus pr-1" />
          {{ $t('add') }}
        </button>
      </div>
    </template>

    <template #content>
      <div class="row">
        <div class="col-lg-12">
          <div class="card card-statistics">
            <div class="card-body">
              <div class="table-responsive">
                <DataTable
                  :always-show-paginator="false"
                  :loading="loading"
                  :paginator="true"
                  :rows="10"
                  :rows-per-page-options="[10, 20, 50]"
                  :value="list"
                  class="table"
                  current-page-report-template="{first}-{last}/{totalRecords}"
                  removable-sort
                  responsive-layout="scroll"
                  sort-field="position"
                  striped-rows>
                  <template #empty>
                    {{ $t('item_label.origin_marketing_empty') }}
                  </template>
                  <Column :header="$t('item_label.label')" field="label">
                    <template #body="slotProps">
                      <span>{{ slotProps.data.label }}</span>
                    </template>
                  </Column>
                  <Column :header="$t('item_label.enabled')" field="enabled">
                    <template #body="slotProps">
                      <i v-if="slotProps.data.enabled" class="ti ti-check text-primary" />
                      <i v-else class="ti ti-close" />
                    </template>
                  </Column>
                  <Column
                    body-style="text-align: right; overflow: visible"
                    header-style=" width: 160px; text-align: btn">
                    <template #body="slotProps">
                      <div class="d-flex justify-content-end">
                        <span
                          class="btn btn-xs btn-inverse-primary mr-1 pointer"
                          @click="openEditDialog(slotProps.data)">
                          <i class="fe fe-edit" />
                        </span>
                        <span class="btn btn-xs btn-inverse-danger pointer" @click="openDeleteDialog(slotProps.data)">
                          <i class="fe fe-trash" />
                        </span>
                      </div>
                    </template>
                  </Column>
                </DataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template #extra>
      <AkDialog
        ref="dialogCreate"
        :title="$t('item_label.origin_marketing')"
        :validate-label="$t('add')"
        width="450px"
        @validate="create()">
        <div class="form-row">
          <AkInputText
            v-model="item.label"
            :label="$t('item_label.label')"
            :validator="v$.item.label"
            class-name="col-md-12" />
          <AkCheckbox v-model="item.enabled" :label="$t('item_label.enabled')" class-name="col-md-12" />
        </div>
      </AkDialog>

      <AkDialog
        ref="dialogEdit"
        :title="$t('item_label.origin_marketing')"
        :validate-label="$t('update')"
        width="450px"
        @validate="update()">
        <div class="form-row">
          <AkInputText
            v-model="item.label"
            :label="$t('item_label.label')"
            :validator="v$.item.label"
            class-name="col-md-12" />
          <AkCheckbox v-model="item.enabled" :label="$t('item_label.enabled')" class-name="col-md-12" />
        </div>
      </AkDialog>

      <AkDialog
        ref="dialogDelete"
        :auto-hide-on-validate="true"
        :cancel-label="$t('no')"
        :title="$t('delete_dialog')"
        :validate-label="$t('yes')"
        width="450px"
        @validate="this.delete()">
        <div class="flex items-center gap-2">
          <Icon icon="fluent:warning-20-regular" class="text-red" width="26" />
          <span>{{ $t('item_label.confirm_delete_origin_marketing') }}</span>
        </div>
      </AkDialog>
    </template>
  </GaView>
</template>
