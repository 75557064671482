<script>
/* VALIDATIONS */
import useVuelidate from '@vuelidate/core';
import {required} from '@vuelidate/validators';

/* COMPOSANTS */
import GaView from '@components/v2/layout/GaView';
import GaListView from '@components/v2/layout/GaListView.vue';
import AkCalendarPeriodFilter from '@components/v2/filter/AkCalendarPeriodFilter.vue';
import AkAutocompleteFilter from '@components/v2/filter/AkAutocompleteFilter.vue';
import AkListFilter from '@components/v2/filter/AkListFilter.vue';
import AkAllFilter from '@components/v2/filter/AkAllFilter.vue';
import AkDatatable from '@components/v2/general/AkDatatable.vue';
import PrestationBadge from '@components/general/PrestationBadge.vue';
import AkDialog from '@components/general/AkDialog.vue';
import AkInputTextArea from '@components/v2/input/AkInputTextArea.vue';
import AkButtonAction from '@components/v2/general/AkButtonAction.vue';
import AkButtonActionValidation from '@components/v2/general/AkButtonActionValidation.vue';

/* MIXINS */
import utilsMixin from '@mixins/utilsMixin';
import randomRef from '@mixins/randomRef';
import permissionsMixin from '@mixins/permissionsMixin';
import sidebarMixin from '@mixins/sidebarMixin';
import loaderMixin from '@mixins/loaderMixin';
import messageMixin from '@mixins/messageMixin';
import filterListMixin from '@mixins/filterListMixin';
import accommodationAutocompleteMixin from '@mixins/autocomplete/accommodationAutocompleteMixin';
import prestationTypesConst from '@mixins/const/prestationTypesConst';
import missionMonitoringStatusConst from '@mixins/const/missionMonitoringStatusConst';

/* SERVICES */
import missionSuiviPartnerService from '@services/missionSuiviPartnerService';

export default {
  components: {
    AkButtonAction,
    AkButtonActionValidation,
    GaView,
    GaListView,
    AkCalendarPeriodFilter,
    AkAutocompleteFilter,
    AkListFilter,
    AkAllFilter,
    AkDatatable,
    PrestationBadge,
    AkDialog,
    AkInputTextArea,
  },
  mixins: [
    utilsMixin,
    randomRef,
    sidebarMixin,
    loaderMixin,
    permissionsMixin,
    messageMixin,
    filterListMixin,
    accommodationAutocompleteMixin,
    prestationTypesConst,
    missionMonitoringStatusConst,
  ],
  metaInfo() {
    return {
      title: 'mission_monitoring.title',
    };
  },
  setup() {
    return {v$: useVuelidate()};
  },
  data() {
    return {
      loading: true,
      filter: {
        status: 'TODO',
        period: [new Date(), new Date(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate())],
      },
      list: [],
      missionCancel: {},
    };
  },
  validations() {
    return {
      missionCancel: {
        id: {required},
        comment: {required},
      },
    };
  },
  computed: {
    isEmptyList() {
      return !this.list || this.list.length === 0;
    },

    missionMonitoringStatusList() {
      return this.missionMonitoringStatusConst.slice(0, -1);
    },
  },
  mounted() {},
  methods: {
    // FILTER

    openFilterPanel() {
      this.toggleFilter('GaMissionSuiviPartnerFilter', this.filter, this.search, this.resetFilter);
    },
    resetFilter() {
      this.filter = {
        status: 'TO_DO',
      };
      this.toggleFilter('GaMissionSuiviPartnerFilter', this.filter, this.search, this.resetFilter);
    },

    // SEARCH

    search() {
      this.loading = true;
      missionSuiviPartnerService
        .suivi(this.filter)
        .then((data) => {
          this.list = data;
        })
        .catch((e) => {
          this.addError(e.response.data.error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    // EXPORT

    exportList() {
      this.showTotalLoaderWithAfter(this.$t('export_in_progress'));
      missionSuiviPartnerService
        .exportList(this.filter)
        .then((data) => {})
        .catch((e) => {
          let message = this.msgForErr(this.$t, e);
          this.addError(message);
        })
        .finally(() => {
          this.hideLoader();
        });
    },

    // COMPLETE

    complete(missionId, init) {
      this.loading = true;

      const updateDTO = {
        id: missionId,
        comment: '',
      };

      missionSuiviPartnerService
        .complete(updateDTO)
        .then(() => {
          this.addSuccess(this.$t('mission_monitoring.success_complete'));
          this.search();
          init();
        })
        .catch((e) => {
          let message = this.msgForErr(this.$t, e);
          this.addError(message);
          this.loading = false;
        });
    },

    // CANCEL

    openCancelDialog(missionId) {
      this.missionCancel = {
        id: missionId,
      };
      this.$refs.dialogCancelMission.show();
    },

    cancel() {
      this.v$.$touch();
      if (this.v$.missionCancel.$error) return;

      this.$refs.dialogCancelMission.hide();

      this.loading = true;

      missionSuiviPartnerService
        .cancel(this.missionCancel)
        .then(() => {
          this.addSuccess(this.$t('mission_monitoring.success_cancel'));
          this.search();
        })
        .catch((e) => {
          let message = this.msgForErr(this.$t, e);
          this.addError(message);
          this.loading = false;
        });
    },

    // UTILS

    isStatusTodo(status) {
      return status === 'TODO';
    },
  },
  watch: {},
};
</script>
<template>
  <GaView>
    <template #action>
      <AkButtonAction
        :disabled="this.isEmptyList"
        secondary
        :label="$t('payment.download')"
        little
        @click="exportList()" />
    </template>

    <template #content>
      <GaListView>
        <template #filter>
          <AkAutocompleteFilter
            v-model="filter.accommodationId"
            :init-method="autocompleteAccommodationById"
            :label="$t('housekeeper.accommodation')"
            :search-label="autocompleteAccommodationQuery"
            :search-method="autocompleteAccommodation"
            direction="right"
            item-display="display"
            item-value="id"
            @search="this.clickSearch" />
          <AkCalendarPeriodFilter
            @search="this.clickSearch"
            label="Période"
            v-model="this.filter.period"
            direction="right" />
          <AkListFilter
            v-model="this.filter.status"
            :items="missionMonitoringStatusList"
            @search="this.clickSearch"
            :label="$t('mission_monitoring.status')"
            itemValue="value"
            itemDisplay="label"
            direction="right" />
          <AkAllFilter @open="openFilterPanel" />
        </template>
        <template #content>
          <AkDatatable :loading="loading" :value="list" paginator @search="search">
            <template #empty>
              {{ $t('mission_monitoring.empty') }}
            </template>

            <Column
              :header="$t('mission_monitoring.prestation_date')"
              field="prestationDate"
              sortable
              style="max-width: 125px; width: 125px">
              <template #body="slotProps">
                <span>
                  {{ this.formatDateIfNeeded(slotProps.data.prestationDate) }}
                </span>
              </template>
            </Column>

            <Column
              :header="$t('mission_monitoring.prestation_type')"
              field="prestationType"
              sortable
              style="width: 200px; min-width: 200px">
              <template #body="slotProps">
                <PrestationBadge :type="slotProps.data.prestationType" :key="slotProps.data.prestationType" />
              </template>
            </Column>

            <Column :header="$t('accommodation.short')" field="accommodationName" sortable>
              <template #body="slotProps">
                <span>
                  <router-link
                    :to="{name: 'accommodationPage', params: {accommodationId: slotProps.data.accommodationId}}">
                    {{ slotProps.data.accommodationName }}
                  </router-link>
                </span>
              </template>
            </Column>

            <Column :header="$t('reservation.one')" field="localizer" sortable style="width: 200px; min-width: 200px">
              <template #body="slotProps">
                <router-link
                  :to="{
                    name: 'reservationPage',
                    params: {
                      reservationId: slotProps.data.reservationId,
                    },
                  }">
                  {{ slotProps.data.localizer }}
                </router-link>
              </template>
            </Column>

            <Column
              :header="$t('reservation.period')"
              field="dateCheckin"
              sortable
              style="max-width: 100px; width: 100px">
              <template #body="slotProps">
                <span>
                  {{ this.displayDatesSeparator(slotProps.data.dateCheckin, slotProps.data.dateCheckout, false) }}
                </span>
              </template>
            </Column>

            <Column
              :header="$t('mission_monitoring.status')"
              field="status"
              sortable
              style="max-width: 100px; width: 100px">
              <template #body="slotProps">
                <span>
                  {{ this.displayForMissionMonitoringStatusConst(slotProps.data.status) }}
                </span>
              </template>
            </Column>

            <Column
              :header="$t('reservation.action')"
              style="width: 200px; min-width: 200px"
              header-class="text-center">
              <template #body="slotProps">
                <div v-if="this.isStatusTodo(slotProps.data.status)" class="flex justify-center gap-2">
                  <AkButtonActionValidation
                    :titlePopup="$t('confirm_dialog')"
                    :messagePopup="$t('mission_monitoring.confirm_finish')"
                    :label="$t('mission_monitoring.finish')"
                    little
                    secondary
                    @click="(init) => this.complete(slotProps.data.id, init)" />
                  <AkButtonAction
                    :label="$t('mission_monitoring.canceled')"
                    little
                    danger
                    @click="this.openCancelDialog(slotProps.data.id)" />
                </div>
              </template>
            </Column>
          </AkDatatable>
        </template>
      </GaListView>
    </template>

    <template #extra>
      <!-- DIALOG CANCEL MISSION -->

      <AkDialog
        ref="dialogCancelMission"
        :title="$t('mission_monitoring.cancel_mission')"
        :validate-label="$t('validate')"
        width="650px"
        @validate="cancel()">
        <div class="flex flex-col">
          <AkInputTextArea
            v-model="missionCancel.comment"
            :label="$t('mission_monitoring.cancel_comment')"
            :placeholder="$t('mission_monitoring.cancel_comment')"
            :validator="v$.missionCancel.comment" />
        </div>
      </AkDialog>
    </template>
  </GaView>
</template>
