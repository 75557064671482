import Api from '@/services/api';
import {removeEmptyParams} from '@utils';

export default {
  notifications(filter) {
    return Api()
      .get('/notifications', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  },
  notification(id) {
    return Api()
      .get('/notification/' + id)
      .then((res) => this.formatRes(res.data));
  },
  create(notification) {
    return Api()
      .post('/notification', notification)
      .then((res) => this.formatRes(res.data));
  },
  update(notification) {
    return Api()
      .put('/notification/' + notification.id, notification)
      .then((res) => this.formatRes(res.data));
  },
  read(filter) {
    return Api()
      .put('/notifications/read', filter)
      .then((res) => this.formatRes(res.data));
  },
  click(notification) {
    return Api()
      .put('/notification/click', notification)
      .then((res) => this.formatRes(res.data));
  },
  delete(notification) {
    return Api()
      .delete('/notification/' + notification.id)
      .then(() => notification);
  },
  formatRes(e) {
    return e;
  },
};
