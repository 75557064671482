<script>
/* COMPOSANTS */

/* SERVICES */
import notificationService from '@services/notificationService';
import GaNotificationItem from '@components/v2/general/GaNotificationItem.vue';
import DropdownMenuAk from '@components/v2/general/DropdownMenuAk.vue';
import {Icon} from '@iconify/vue';

export default {
  components: {Icon, DropdownMenuAk},
  props: {
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    direction: {
      type: String,
      required: false,
      default: 'left',
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    scrollToTop() {
      this.$nextTick(() => {
        this.$refs.scroll.$el.scrollTop = 0;
      });
    },
    hide() {
      this.$refs.dropdown.hide();
    },
  },
  computed: {},
};
</script>

<template>
  <dropdown-menu-ak
    ref="dropdown"
    :blockScroll="true"
    class="dropdown-help"
    :direction="this.direction"
    @opened="scrollToTop"
    :overlay="true">
    <template #trigger>
      <Icon icon="icon-park-solid:info" width="16" height="16" color="var(--color-dark)" class="cursor-pointer" />
    </template>
    <template #body>
      <div class="flex flex-col">
        <span class="text-dark font-inter font-semibold text-lg pb-6">{{ title }}</span>
        <perfect-scrollbar ref="scroll" class="md:max-h-[calc(50vh_-_81px)] max-h-[calc(75vh_-_81px)]">
          <span class="text-justify whitespace-pre-wrap">{{ message }}</span>
        </perfect-scrollbar>
      </div>
    </template>
  </dropdown-menu-ak>
</template>
