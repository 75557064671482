import Api from '@/services/api';
import {removeEmptyParams} from '@utils';

export default {
  accommodationRoles(filter) {
    return Api()
      .get('/accommodationroles', {params: removeEmptyParams(filter)})
      .then((res) => res.data);
  },
  mesContacts(filter) {
    return Api()
      .get('/accommodationroles/mescontacts', {params: removeEmptyParams(filter)})
      .then((res) => res.data);
  },
  createForCurrent(dto) {
    return Api().post('/accommodationrole/current', dto);
  },
  createForEmployee(dto) {
    return Api().post('/accommodationrole/employee', dto);
  },
  delete(id) {
    return Api()
      .delete('/accommodationrole/' + id)
      .then(() => id);
  },
};
