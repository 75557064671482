import {defineStore} from 'pinia';

export const useMessageStore = defineStore('messages', {
  state: () => ({list: []}),
  getters: {
    messages: (state) => state.list,
  },
  actions: {
    addError(content, reset = true, closable = true, hasLifeTime = true) {
      if (reset) this.resetMessages();
      this.list.push({id: this.generateUUID(), closable: closable, severity: 'error', content: content});
      if (hasLifeTime) this.setLastMessageLifeTime(5000);
    },
    addWarning(content, reset = true, closable = true) {
      if (reset) this.resetMessages();
      this.list.push({id: this.generateUUID(), closable: closable, severity: 'warn', content: content});
      this.setLastMessageLifeTime(5000);
    },
    success(content, reset = true, closable = true) {
      if (reset) this.resetMessages();
      this.list.push({id: this.generateUUID(), closable: closable, severity: 'success', content: content});
      this.setLastMessageLifeTime(5000);
    },
    addSuccess(content, reset = true, closable = true) {
      if (reset) this.resetMessages();
      this.list.push({id: this.generateUUID(), closable: closable, severity: 'success', content: content});
      this.setLastMessageLifeTime(5000);
    },
    resetMessages() {
      this.list = [];
    },
    closeMessage(message) {
      this.list = this.list.filter((val) => val.id !== message);
    },
    generateUUID() {
      // Public Domain/MIT
      var d = new Date().getTime();
      if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
        d += performance.now(); //use high-precision timer if available
      }
      var newGuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
      });

      return newGuid;
    },
    setLastMessageLifeTime(lifeTime) {
      let message = this.list[this.list.length - 1];
      let that = this;
      setTimeout(function () {
        that.closeMessage(message.id);
      }, lifeTime);
    },
  },
});
