<script>
import {Icon} from '@iconify/vue';
import {required} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

/* COMPOSANTS */
import AkInputText from '@components/input/AkInputText';
import AkDialog from '@components/general/AkDialog';
import GaView from '@components/layout/GaView';

/* MIXINS */
import randomRef from '@mixins/randomRef';
import utilsMixin from '@mixins/utilsMixin';

/* SERVICES */
import stockService from '@services/stockService';

export default {
  components: {
    Icon,
    AkDialog,
    AkInputText,
    GaView,
  },
  mixins: [randomRef, utilsMixin],
  metaInfo() {
    return {
      title: 'settings.stocks',
    };
  },
  setup() {
    return {v$: useVuelidate()};
  },
  data() {
    return {
      list: [],
      item: {},
    };
  },
  validations() {
    return {
      item: {
        label: {required},
      },
    };
  },
  mounted() {
    this.getRef().showTotalLoader();
    stockService.stocks().then((data) => {
      this.list = data;
      this.getRef().hideLoader();
    });
  },
  methods: {
    create() {
      this.v$.$touch();
      if (this.v$.$error) return;

      this.getRef().showTotalLoader();
      stockService
        .create(this.item)
        .then((data) => {
          this.list.push(data);
          this.item = {};
          this.getRef().success(this.$t('stock.added'));
        })
        .finally(() => {
          this.getRef().hideLoader();
          this.$refs.dialogCreate.hide();
        });
    },
    update() {
      this.v$.$touch();
      if (this.v$.$error) return;

      this.getRef().showTotalLoader();
      stockService
        .update(this.item)
        .then((data) => {
          this.replaceInListById(this.list, data);
          this.getRef().success(this.$t('stock.updated'));
        })
        .finally(() => {
          this.getRef().hideLoader();
          this.$refs.dialogEdit.hide();
        });
    },
    delete() {
      stockService
        .delete(this.item)
        .then((data) => {
          this.removeInListById(this.list, data.id);
          this.getRef().success(this.item.label + ' a bien été supprimé');
        })
        .catch((e) => {
          let content = 'Une erreur est survenue durant la suppression';
          if (e.response) {
            content = 'Une erreur est survenue durant la suppression : ' + e.response.data.error;
          }
          this.getRef().error(content);
        });
    },
    openCreateDialog() {
      this.item = {};
      this.$refs.dialogCreate.show();
    },
    openEditDialog(item) {
      this.item = {...item};
      this.$refs.dialogEdit.show();
    },
    openDeleteDialog(item) {
      this.item = item;
      this.$refs.dialogDelete.show();
    },
  },
};
</script>

<template>
  <GaView :ref="ref" :title="$t('settings.stocks')">
    <template #action>
      <div class="d-flex justify-content-end">
        <button class="btn btn-primary float-right" @click="openCreateDialog()">
          <i class="gst-plus pr-1" />{{ $t('add') }}
        </button>
      </div>
    </template>

    <template #content>
      <DataTable
        :always-show-paginator="false"
        :paginator="true"
        :rows="10"
        :rows-per-page-options="[10, 20, 50]"
        :value="list"
        class="table"
        current-page-report-template="{first}-{last}/{totalRecords}"
        removable-sort
        responsive-layout="scroll"
        sort-field="position"
        striped-rows>
        <template #empty>
          {{ $t('stock.empty') }}
        </template>

        <Column :header="$t('item_label.label')" field="label">
          <template #body="slotProps">
            <router-link :to="{name: 'stockPage', params: {stockId: slotProps.data.id}}">
              {{ slotProps.data.label }}
            </router-link>
          </template>
        </Column>
        <Column body-style="text-align: right; overflow: visible" header-style=" width: 160px; text-align: btn">
          <template #body="slotProps">
            <div class="d-flex justify-content-end">
              <router-link
                :to="{name: 'stockPage', params: {stockId: slotProps.data.id}}"
                class="btn btn-xs btn-inverse-primary mr-1">
                <i class="fe fe-eye" />
              </router-link>
              <span class="btn btn-xs btn-inverse-primary mr-1 pointer" @click="openEditDialog(slotProps.data)">
                <i class="fe fe-edit" />
              </span>
              <span class="btn btn-xs btn-inverse-danger pointer" @click="openDeleteDialog(slotProps.data)">
                <i class="fe fe-trash" />
              </span>
            </div>
          </template>
        </Column>
      </DataTable>
    </template>

    <template #extra>
      <AkDialog
        ref="dialogCreate"
        :title="$t('stock.add')"
        :validate-label="$t('add')"
        width="450px"
        @validate="create()">
        <div class="form-row">
          <AkInputText v-model="item.label" :label="$t('stock.label')" :validator="v$.item.label" class-name="col-12" />
        </div>
      </AkDialog>

      <AkDialog
        ref="dialogEdit"
        :title="$t('stock.update')"
        :validate-label="$t('update')"
        width="450px"
        @validate="update()">
        <div class="form-row">
          <AkInputText v-model="item.label" :label="$t('stock.label')" :validator="v$.item.label" class-name="col-12" />
        </div>
      </AkDialog>

      <AkDialog
        ref="dialogDelete"
        :auto-hide-on-validate="true"
        :cancel-label="$t('no')"
        :title="$t('delete_dialog')"
        :validate-label="$t('yes')"
        width="450px"
        @validate="this.delete()">
        <div class="flex items-center gap-2">
          <Icon icon="fluent:warning-20-regular" class="text-red" width="26" />
          <span>{{ $t('stock.confirm_delete') }}</span>
        </div>
      </AkDialog>
    </template>
  </GaView>
</template>
