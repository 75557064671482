<script>
/* COMPOSANTS */

/* SERVICES */
import DropdownMenu from 'v-dropdown-menu';
import notificationService from '@services/notificationService';
import utilsMixin from '@mixins/utilsMixin';

export default {
  components: {},
  mixins: [utilsMixin],
  props: {
    modelValue: {required: true},
  },
  emits: ['close'],
  data() {
    return {};
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    iconClass() {
      if (this.isEnabled) {
        return 'text-dark';
      }
      return 'text-gray';
    },
    bgClass() {
      if (!this.isEnabled) {
        return 'bg-grayBg';
      }
      return 'bg-secondaryBg';
    },
    bgEnabledClass() {
      if (this.isEnabled) return 'bg-primary';
      return 'bg-gray';
    },
    opacityClass() {
      if (this.isEnabled) return 'opacity-100';
      return ' opacity-80';
    },
    isEnabled() {
      return !this.currentValue.dateClick && this.route;
    },
    msgParams() {
      return this.currentValue.msgParams ? JSON.parse(this.currentValue.msgParams) : [];
    },
    route() {
      if (!this.currentValue.routeName) return null;
      if (this.currentValue.routeOver) return null;

      let routeName = this.currentValue.routeName;
      let routeParams = this.currentValue.routeParams;

      if (routeParams) {
        let params = JSON.parse(routeParams);
        if (routeName === 'suivis') return {name: routeName, hash: params};
        return {name: routeName, params: params};
      }
      return {name: routeName};
    },
  },
  methods: {
    clickNotification() {
      this.$emit('close');
      notificationService.click(this.currentValue).then(() => {
        this.currentValue.dateClick = new Date();
      });
      let route = this.route;
      if (route) {
        //hack pour recharger la page courante si besoin
        let router = this.$router;
        if (router.currentRoute.value.name === route.name) router.go();
        else router.push(route);
      }
    },
  },
};
</script>

<template>
  <li class="hover:cursor-pointer flex gap-2" :class="opacityClass" @click="clickNotification">
    <div class="flex items-center">
      <div class="rounded-full w-42 h-42 flex items-center justify-center" :class="this.bgClass">
        <i class="ga-icon ga-success_document text-3xl" :class="this.iconClass" />
      </div>
    </div>
    <div class="flex flex-col w-full">
      <div class="flex items-center justify-between">
        <p class="font-semibold font-inter text-dark flex-1">
          {{ $t('notifications.' + this.currentValue.type.toLowerCase()) }}
        </p>
        <div class="flex gap-1.5 items-center">
          <span class="font-inter text-gray font-normal text-xs">{{
            durationFromNow(this.currentValue.dateCreated)
          }}</span>
          <i :class="bgEnabledClass" class="rounded-full w-2.5 h-2.5" />
        </div>
      </div>
      <span class="text-sm font-inter text-dark">{{ $t('notifications.' + this.currentValue.msgKey, msgParams) }}</span>
    </div>
  </li>
</template>
