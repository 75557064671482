<script>
import {Icon} from '@iconify/vue';
import moment from 'moment/moment';

/* COMPOSANTS */
import OverlayPanel from 'primevue/overlaypanel';
import AkDialog from '@components/general/AkDialog';

/* MIXINS */
import smilyStatusConst from '@mixins/const/smilyStatusConst';
import reservationTypesConst from '@mixins/const/reservationTypesConst';
import blockadeReasonsConst from '@mixins/const/blockadeReasonsConst';
import utilsMixin from '@mixins/utilsMixin';
import permissionsMixin from '@mixins/permissionsMixin';
import loaderMixin from '@mixins/loaderMixin';
import messageMixin from '@mixins/messageMixin';

/* SERVICES */
import reservationService from '@services/reservationService';
import reservationPlanningService from '@services/reservationPlanningService';
import upsellStatusConst from '@mixins/const/upsellStatusConst';

export default {
  components: {AkDialog, Icon, OverlayPanel},
  emits: ['needReload', 'edit-reservation', 'add-reservation'],
  mixins: [
    smilyStatusConst,
    blockadeReasonsConst,
    permissionsMixin,
    reservationTypesConst,
    utilsMixin,
    messageMixin,
    upsellStatusConst,
    loaderMixin,
  ],
  props: {
    modelValue: {
      required: true,
    },
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    title() {
      if (!this.currentValue) return '';
      let type = this.currentValue.type;
      let title = this.currentValue.title;

      if (type === 'GUEST') return this.currentValue.guestDisplay;
      if (type === 'BLOCKADE') return this.displayForBlockadeReason(title);
      if (type === 'OWNER') return this.displayForReservationTypeConst(type);
      return '';
    },
    period() {
      if (!this.currentValue) return '';
      let dateStart = this.currentValue.dateStart;
      let dateEnd = this.currentValue.dateEnd;

      let start = dateStart ? this.displayDate(dateStart) : '';
      let end = dateEnd ? this.displayDate(dateEnd) : '';
      return start + ' - ' + end;
    },
    offer() {
      if (!this.currentValue) return '';
      return this.currentValue.offer;
    },
    statusSmily() {
      if (!this.currentValue || !this.currentValue.statusSmily) return '';
      return this.displayForSmilyStatusConst(this.currentValue.statusSmily);
    },
    dateCreated() {
      if (!this.currentValue) return '';
      let date = this.currentValue.dateCreated;
      return date ? this.displayDate(date) : '-';
    },
    dateCheckin() {
      if (!this.currentValue) return '';
      let date = this.currentValue.dateCheckin;
      return date ? this.displayTime(date) : '-';
    },
    dateCheckout() {
      if (!this.currentValue) return '';
      let date = this.currentValue.dateCheckout;
      return date ? this.displayTime(date) : '-';
    },
    isBookedReservation() {
      return this.currentValue.statusSmily !== 'Canceled' && this.currentValue.type === 'GUEST';
    },
    isOfferAllInclusive() {
      return this.offer === 'ALL_INCLUSIVE';
    },
    isPeticionInformacion() {
      return this.currentValue.statusSmily === 'Tentative' && !this.isOwner();
    },
    canDisplayYagooLink() {
      return (
        this.currentValue.boardingPass &&
        moment(this.currentValue.dateEnd).add(1, 'day').startOf('day').isAfter(moment())
      );
    },
    displayCommission() {
      if (this.isOwner()) {
        let val = this.currentValue.commission ? this.currentValue.commission : 0;
        if (this.isSiteVga) {
          val += this.currentValue.feeService ? this.currentValue.feeService : 0;
        }
        return this.formatCurrency(val, this.currentValue.currency);
      } else {
        let display = this.formatCurrency(this.currentValue.commission, this.currentValue.currency);
        if (this.isSiteVga) {
          display += ' (' + this.formatCurrency(this.currentValue.feeService, this.currentValue.currency) + ')';
        }
        return display;
      }
    },
    isSiteVga() {
      if (!this.currentValue.reservationSite) return false;
      return (
        this.currentValue.reservationSite.includes('happystay') ||
        this.currentValue.reservationSite.includes('guest-adom')
      );
    },
    canDeleteReservation() {
      let oldHour = moment(this.currentValue.dateCheckin).hour();
      let oldMin = moment(this.currentValue.dateCheckin).minute();
      let checkIn = moment(this.currentValue.dateStart).hour(oldHour).minute(oldMin);
      return (
        this.currentValue.statusSmily === 'Unavailable' &&
        this.currentValue.type === 'OWNER' &&
        this.currentValue.externalId != undefined &&
        moment(this.currentValue.dateEnd).isAfter(moment()) &&
        checkIn.isAfter(moment())
      );
    },
    canUpdateReservation() {
      let oldHour = moment(this.currentValue.dateCheckin).hour();
      let oldMin = moment(this.currentValue.dateCheckin).minute();
      let checkIn = moment(this.currentValue.dateStart).hour(oldHour).minute(oldMin);
      return (
        this.currentValue.statusSmily === 'Unavailable' &&
        this.currentValue.type === 'OWNER' &&
        this.currentValue.externalId != undefined &&
        moment(this.currentValue.dateEnd).isAfter(moment()) &&
        checkIn.isAfter(moment())
      );
    },
    hasUpsell() {
      return this.currentValue.upsellList && this.currentValue.upsellList.length > 0;
    },
  },
  methods: {
    hide() {
      this.$refs.op.hide();
    },
    toggle(event, target) {
      this.$refs.op.toggle(event, target);
    },
    exclude(reservation) {
      reservationService.exclude(reservation).then((data) => {
        this.$refs.op.hide();
        reservation.hidden = true;
      });
    },
    openDeleteDialog(event) {
      this.$refs.dialogConfirmDelete.show();
      if (event) event.stopPropagation();
    },
    cancelReservation() {
      this.showTotalLoaderWithAfter('Suppresion en cours');
      reservationPlanningService
        .cancelReservationHote(this.currentValue)
        .then((data) => {
          this.$emit('needReload', true);
        })
        .catch(() => {
          this.$emit('needReload', false);
        })
        .finally(() => {
          this.hideLoader();
          this.$refs.op.hide();
        });
    },
  },
};
</script>

<template>
  <OverlayPanel
    ref="op"
    :breakpoints="{'960px': '75vw', '640px': '100vw'}"
    :dismissable="true"
    :show-close-icon="true"
    :style="{width: '450px'}">
    <div v-if="currentValue">
      <div class="d-flex justify-content-between align-items-center">
        <h4>Réservation {{ currentValue.localizer }}</h4>
      </div>
      <h5>Données réservation</h5>
      <p>
        <b>Logement :</b
        ><span v-if="!this.isOwner()"
          ><span class="poppins-bold text-uppercase">{{ currentValue.ownerLastName }}</span>
          {{ currentValue.ownerFirstName }} •</span
        >
        {{ currentValue.accommodationName }}
        <Icon
          v-if="currentValue.physical && !this.isOwner()"
          class="icon-tooltip"
          color="black"
          icon="mdi:human-greeting" />
        <Icon
          v-if="currentValue.laundry && !this.isOwner()"
          class="icon-tooltip"
          color="black"
          icon="iconoir:bed-ready" />
      </p>
      <p>
        <b>Date de création de réservation :</b>
        {{ dateCreated }}
      </p>
      <p>
        <b>Statut Smily de la réservation :</b>
        {{ statusSmily }}
      </p>
      <p>
        <b>Date :</b>
        {{ period }}
      </p>
      <p>
        <b>Heure du checkin :</b>
        {{ dateCheckin }}
      </p>
      <p>
        <b>Heure de check-out :</b>
        {{ dateCheckout }}
      </p>
      <p>
        <b>OTA :</b>
        {{ currentValue.reservationSite }}
      </p>
      <p v-if="this.currentValue.type === 'GUEST' && this.currentValue.statusSmily === 'Booked' && canDisplayYagooLink">
        <b>Livret d'accueil :</b>
        <a :href="this.currentValue.boardingPass" v-if="this.currentValue.boardingPass" target="_blank">
          <Icon class="icon-book-link" icon="fluent:book-open-globe-24-regular" />
        </a>
      </p>
      <p>
        <b>Livret d'accueil Enso :</b>
        {{ currentValue.boardingPass }}
      </p>

      <h5 class="mt-1">Données voyageur</h5>
      <p><b>Client : </b>{{ title }}</p>
      <p>
        <b>Adultes : </b> {{ currentValue.nbAdults }} / <b>Enfants : </b> {{ currentValue.nbChildren }} /
        <b>Bébés : </b> {{ currentValue.nbBabies }}
      </p>
      <p><b>Téléphone : </b>{{ currentValue.guestPhone }}</p>

      <div v-if="this.isBookedReservation && !this.isPartenaire() && !this.isHousekeeper()">
        <h5 class="mt-1">Revenus</h5>
        <p>
          <b>Montant total : </b>
          {{ formatCurrency(currentValue.price, currentValue.currency) }}
        </p>
        <p>
          <b>Commission de la plateforme : </b>
          {{ displayCommission }}
        </p>
        <p>
          <b>Taxe de séjour : </b>
          {{ formatCurrency(currentValue.tdsAmount ? currentValue.tdsAmount : 0, currentValue.currency) }}
          <span v-if="currentValue.tdsAmountForAirbnb">
            ({{
              formatCurrency(
                currentValue.tdsAmountForAirbnb ? currentValue.tdsAmountForAirbnb : 0,
                currentValue.currency,
              )
            }})</span
          >
        </p>
        <p>
          <b>Montant des nuits : </b>
          {{ formatCurrency(currentValue.nightsPrice, currentValue.currency) }}
        </p>
        <p>
          <b>Revenu Hôte : </b>
          {{ formatCurrency(currentValue.priceOwner, currentValue.currency) }}
        </p>
        <p v-if="!this.isOwner()">
          <b>Revenu Guestadom : </b>
          {{ formatCurrency(currentValue.priceGas, currentValue.currency) }}
        </p>
        <p v-if="!this.isOwner() && this.isOfferAllInclusive">
          <b>Revenu conciergerie locale : </b>
          {{ formatCurrency(currentValue.priceGav, currentValue.currency) }}
        </p>

        <p v-if="this.isOwner()">
          <b>Revenu Guestadom : </b>
          {{ formatCurrency(currentValue.priceGav + currentValue.priceGas, currentValue.currency) }}
        </p>
      </div>

      <div v-if="this.hasUpsell && this.isGasGav()">
        <h5 class="mt-1">Extras</h5>

        <div v-for="u in this.currentValue.upsellList" :key="u.id">
          <div class="d-flex flex-column mb-1">
            <span>{{ u.name }} - {{ u.amount }}€</span>
            <span
              >{{ this.displayForUpsellStatusConst(u.status) }} -
              {{ this.displayForUpsellStatusEnsoConst(u.statusEnso) }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <div v-if="isPeticionInformacion" class="mt-2">
      <span class="btn btn-primary button pointer" @click="exclude(currentValue)"> Retirer la réservation </span>
    </div>
    <div v-if="canUpdateReservation" class="mt-2">
      <span class="btn btn-primary button pointer" @click="this.$emit('editReservation', this.currentValue)">
        Modifier cette réservation hôte
      </span>
    </div>
    <div v-if="canDeleteReservation" class="mt-2">
      <span class="btn btn-outline-danger btn-outline button pointer" @click="openDeleteDialog">
        Supprimer cette réservation hôte
      </span>
    </div>
  </OverlayPanel>

  <AkDialog
    ref="dialogConfirmDelete"
    :auto-hide-on-validate="true"
    :cancel-label="$t('no')"
    :title="$t('confirm_dialog')"
    :validate-label="$t('yes')"
    width="450px"
    @validate="this.cancelReservation">
    <div class="flex items-center gap-2">
      <Icon icon="fluent:warning-20-regular" class="text-red" width="26" />
      <span>{{ $t('unavailability.confirm_delete') }}</span>
    </div>
  </AkDialog>
</template>

<style>
.p-overlaypanel-content p {
  color: black;
}
</style>
