<script>
import useVuelidate from '@vuelidate/core';
import {maxLength, required, requiredIf} from '@vuelidate/validators';

/* COMPOSANTS */
import AkCalendar from '@components/v2/input/AkCalendar';
import AkDropdown from '@components/v2/input/AkDropdown';
import AkDropdownGroup from '@components/v2/input/AkDropdownGroup';
import AkFilesUpload from '@components/v2/input/AkFilesUpload';
import AkFormSubmitted from '@components/general/AkFormSubmitted';
import AkInputText from '@components/v2/input/AkInputText';
import AkInputTextArea from '@components/v2/input/AkInputTextArea';
import AkMultiSelect from '@components/v2/input/AkMultiSelect';
import GaMessages from '@components/ga/layout/GaMessages';
import AkAutocomplete from '@components/v2/input/AkAutocomplete';
import AkSidebar from '@components/v2/general/AkSidebar';

/* MIXINS */
import messageMixin from '@mixins/messageMixin';
import permissionsMixin from '@mixins/permissionsMixin';
import randomRef from '@mixins/randomRef';
import ticketPriorityConst from '@mixins/const/ticketPriorityConst';
import ticketStatusConst from '@mixins/const/ticketStatusConst';
import utilsMixin from '@mixins/utilsMixin';
import ownerAutocompleteMixin from '@mixins/autocomplete/ownerAutocompleteMixin';
import clientAutocompleteMixin from '@mixins/autocomplete/clientAutocompleteMixin';
import companyMixin from '@mixins/companyMixin';
import poleMixin from '@mixins/poleMixin';

/* SERVICES */
import accommodationService from '@services/accommodationService';
import ticketCategoryService from '@services/ticketCategoryService';
import ticketInterlocutorTypeConst from '@mixins/const/ticketInterlocutorTypeConst';
import ticketService from '@services/ticketService';
import AkButtonFooterSidebar from '@components/v2/general/AkButtonFooterSidebar.vue';

export default {
  components: {
    AkButtonFooterSidebar,
    AkSidebar,
    AkAutocomplete,
    GaMessages,
    AkCalendar,
    AkDropdown,
    AkDropdownGroup,
    AkFilesUpload,
    AkFormSubmitted,
    AkInputText,
    AkInputTextArea,
    AkMultiSelect,
  },
  mixins: [
    messageMixin,
    permissionsMixin,
    randomRef,
    ticketInterlocutorTypeConst,
    ticketPriorityConst,
    ticketStatusConst,
    utilsMixin,
    companyMixin,
    poleMixin,
    ownerAutocompleteMixin,
    clientAutocompleteMixin,
  ],
  props: {
    source: {
      type: String,
      default: undefined,
    },
  },
  setup() {
    return {v$: useVuelidate()};
  },
  emits: ['create-done'],
  data() {
    return {
      createSidebarVisible: false,
      fromHeader: false,
      ticket: {
        status: 'NEW',
        priority: 'MEDIUM',
        interlocutorTypes: [],
        poleIds: [],
      },
      files: [],
      companyId: null,
      ownerAccommodations: [],
      categories: [],
      submitted: false,
      errors: [],
    };
  },
  validations() {
    return {
      ticket: {
        description: {required, maxLengthValue: maxLength(5000)},
        status: {required},
        priority: {required},
        poleIds: {required},
        subCategoryId: {required: requiredIf(this.isCategoryInputAvailable)},
        label: {required: requiredIf(this.isLabelRequired), maxLengthValue: maxLength(500)},
        companyId: {required: requiredIf(this.isCompanyRequired)},
        ownerId: {required: requiredIf(this.isOwnerRequired)},
        prospectId: {required: requiredIf(this.isProspectRequired)},
        clientId: {required: requiredIf(this.isClientRequired)},
        accommodationId: {required: requiredIf(this.isAccommodationRequired)},
        interlocutorTypes: {required: requiredIf(this.isInterlocutorTypeInputAvailable)},
      },
      files: {required: requiredIf(this.isFilesRequired)},
    };
  },
  watch: {
    'ticket.companyId'(newVal, oldVal) {
      this.companyId = newVal;
      this.ticket.ownerId = null;
      this.ticket.accommodationId = null;
      this.ticket.clientId = null;
      this.ticket.prospectId = null;
      if (this.$refs.ownerAutocomplete) this.$refs.ownerAutocomplete.reset();
      if (this.$refs.prospectAutocomplete) this.$refs.prospectAutocomplete.reset();
      if (this.$refs.clientAutocomplete) this.$refs.clientAutocomplete.reset();
    },
    'ticket.ownerId'(newVal, oldVal) {
      this.initOwnerAccommodations();
    },
    'ticket.poleIds'(newVal, oldVal) {
      this.ticket.interlocutorTypes = [];
      let poleAgence = newVal.find((pole) => pole.id === -1);
      console.log(newVal);
      if (!poleAgence) {
        this.ticket.companyId = null;
      }
      this.ticket.subCategoryId = null;
    },
    'ticket.interlocutorTypes'(newVal, oldVal) {
      if (!this.isInterlocuteurProprietaireSelected) this.ticket.ownerId = null;
      if (!this.isInterlocuteurAccommodationSelected) this.ticket.accommodationId = null;
      if (!this.isInterlocuteurProspectSelected) this.ticket.prospectId = null;
      if (!this.isInterlocuteurVoyageurSelected) this.ticket.clientId = null;
    },
  },
  methods: {
    showFromHeader() {
      this.fromHeader = true;
      this.show();
    },
    show() {
      this.createSidebarVisible = true;
      this.initTicket();

      let p2 = ticketCategoryService.ticketCategoriesForCreation();
      p2.then((data) => {
        this.categories = data;
      }).catch((e) => {
        this.$refs.sidebarMessages.error(this.$t('ticket.error'));
      });
    },
    hide() {
      this.createSidebarVisible = false;
    },
    initTicket() {
      if (this.$refs.fileUpload) this.$refs.fileUpload.clear();
      this.ticket = {
        status: 'NEW',
        priority: 'MEDIUM',
        interlocutorTypes: [],
        poleIds: [],
      };
      if (this.source === 'header') {
        this.ticket.poleIds = [this.poles.find((pole) => pole.technicalKey === 'POLE_TECH').id];
      }
      this.files = [];
    },
    create() {
      this.errors = [];
      this.submitted = true;
      this.v$.$touch();

      if (this.v$.files.$errors.length > 0) {
        this.$refs.sidebarMessages.error(this.$t('ticket.error_empty_files'));
      }
      if (this.v$.$error) {
        this.$refs.footerSidebar.init();
        return;
      }

      if (!this.isAccommodationInputAvailable) this.ticket.accommodationId = null;
      if (!this.isOwnerInputAvailable) this.ticket.ownerId = null;
      if (!this.isClientInputAvailable) this.ticket.clientId = null;
      if (!this.isProspectInputAvailable) this.ticket.prospectId = null;

      ticketService
        .create(this.ticket, this.files)
        .then(() => {
          this.submitted = false;
          this.success(this.$t('ticket.added'));
          this.$store.commit('TICKET_STATUS_CHANGED');
          this.$emit('create-done');
          this.$nextTick(() => {
            this.resetAutocomplete();
            this.initTicket();
            this.hide();
          });
        })
        .catch((e) => {
          if (e.response) {
            this.$refs.sidebarMessages.error(this.$t('error.' + e.response.data.error));
          }
        })
        .finally(() => {
          this.$refs.fileUpload.clear();
          this.files = [];
        });
    },
    resetAutocomplete() {
      if (this.$refs.ownerAutocomplete) this.$refs.ownerAutocomplete.reset();
      if (this.$refs.prospectAutocomplete) this.$refs.prospectAutocomplete.reset();
      if (this.$refs.clientAutocomplete) this.$refs.clientAutocomplete.reset();
    },
    initOwnerAccommodations() {
      this.ownerAccommodations = [];
      this.ticket.accommodationId = null;

      if (this.ticket.ownerId !== null) {
        let filter = {ownerId: this.ticket.ownerId};
        if (this.companyId) {
          filter.companyId = this.companyId;
        }
        accommodationService
          .accommodations(filter)
          .then((data) => {
            this.ownerAccommodations = data;
          })
          .catch((error) => {
            this.$refs.sidebarMessages.error(this.$t('ticket.error'));
          });
      }
    },
    autocompleteOwnerCustomerByCompany() {
      return {ownerStatus: 'CUSTOMER', companyId: this.companyId};
    },
    autocompleteOwnerProspectByCompany() {
      return {ownerStatus: 'PROSPECT', companyId: this.companyId};
    },
    autocompleteClientByCompany() {
      return {companyId: this.companyId};
    },
  },
  computed: {
    // POLE IDS

    poleProduitId() {
      let pole = this.poles.find((pole) => pole.technicalKey === 'POLE_TECH');
      return pole ? pole.id : null;
    },
    poleHoteId() {
      let pole = this.poles.find((pole) => pole.technicalKey === 'POLE_HOTE');
      return pole ? pole.id : null;
    },
    poleVoyageurId() {
      let pole = this.poles.find((pole) => pole.technicalKey === 'POLE_VOYAGEUR');
      return pole ? pole.id : null;
    },
    poleComptabiliteMandanteSortanteId() {
      let pole = this.poles.find((pole) => pole.technicalKey === 'POLE_COMPTABILITE_MANDANTE_SORTANTE');
      return pole ? pole.id : null;
    },
    poleComptabiliteMandanteEntranteId() {
      let pole = this.poles.find((pole) => pole.technicalKey === 'POLE_COMPTABILITE_MANDANTE_ENTRANTE');
      return pole ? pole.id : null;
    },
    poleFinanceId() {
      let pole = this.poles.find((pole) => pole.technicalKey === 'POLE_COMPTABILITE_GENERALE');
      return pole ? pole.id : null;
    },
    poleRhAdministratifId() {
      let pole = this.poles.find((pole) => pole.technicalKey === 'POLE_ADMINISTRATIF');
      return pole ? pole.id : null;
    },
    poleRhRecrutementId() {
      let pole = this.poles.find((pole) => pole.technicalKey === 'POLE_RH');
      return pole ? pole.id : null;
    },
    poleCommercialId() {
      let pole = this.poles.find((pole) => pole.technicalKey === 'POLE_SALES');
      return pole ? pole.id : null;
    },
    poleDistributionRmId() {
      let pole = this.poles.find((pole) => pole.technicalKey === 'POLE_REVENUE_MANAGEMENT');
      return pole ? pole.id : null;
    },
    poleMarketcommId() {
      let pole = this.poles.find((pole) => pole.technicalKey === 'POLE_MARKETCOMM');
      return pole ? pole.id : null;
    },
    poleDistributionOnboardingId() {
      let pole = this.poles.find((pole) => pole.technicalKey === 'POLE_DISTRIBUTION');
      return pole ? pole.id : null;
    },
    poleCityManagerId() {
      let pole = this.poles.find((pole) => pole.technicalKey === 'POLE_CITY_MANAGER');
      return pole ? pole.id : null;
    },
    poleOperationsId() {
      let pole = this.poles.find((pole) => pole.technicalKey === 'POLE_OPERATIONS');
      return pole ? pole.id : null;
    },

    // POLES SELECTIONNES

    selectedPoleIds() {
      return this.ticket.poleIds.filter((id) => id !== -1);
    },
    hasPoleSelected() {
      return this.selectedPoleIds.length > 0;
    },
    isSinglePoleSelected() {
      return this.selectedPoleIds.length === 1;
    },
    isMultiPoleSelected() {
      return this.ticket.poleIds.length > 1;
    },
    isPoleProduitSelected() {
      return this.isSinglePoleSelected && this.selectedPoleIds[0] === this.poleProduitId;
    },
    isPoleHoteSelected() {
      return this.isSinglePoleSelected && this.selectedPoleIds[0] === this.poleHoteId;
    },
    isPoleVoyageurSelected() {
      return this.isSinglePoleSelected && this.selectedPoleIds[0] === this.poleVoyageurId;
    },
    isPoleComptabiliteMandanteSortanteSelected() {
      return this.isSinglePoleSelected && this.selectedPoleIds[0] === this.poleComptabiliteMandanteSortanteId;
    },
    isPoleComptabiliteMandanteEntranteSelected() {
      return this.isSinglePoleSelected && this.selectedPoleIds[0] === this.poleComptabiliteMandanteEntranteId;
    },
    isPoleFinanceSelected() {
      return this.isSinglePoleSelected && this.selectedPoleIds[0] === this.poleFinanceId;
    },
    isPoleRhAdministratifSelected() {
      return this.isSinglePoleSelected && this.selectedPoleIds[0] === this.poleRhAdministratifId;
    },
    isPoleRhRecrutementSelected() {
      return this.isSinglePoleSelected && this.selectedPoleIds[0] === this.poleRhRecrutementId;
    },
    isPoleCommercialSelected() {
      return this.isSinglePoleSelected && this.selectedPoleIds[0] === this.poleCommercialId;
    },
    isPoleDistributionRmSelected() {
      return this.isSinglePoleSelected && this.selectedPoleIds[0] === this.poleDistributionRmId;
    },
    isPoleMarketcommSelected() {
      return this.isSinglePoleSelected && this.selectedPoleIds[0] === this.poleMarketcommId;
    },
    isPoleDistributionOnboardingSelected() {
      return this.isSinglePoleSelected && this.selectedPoleIds[0] === this.poleDistributionOnboardingId;
    },
    isPoleCityManagerSelected() {
      return this.isSinglePoleSelected && this.selectedPoleIds[0] === this.poleCityManagerId;
    },
    isPoleOperationsSelected() {
      return this.isSinglePoleSelected && this.selectedPoleIds[0] === this.poleOperationsId;
    },
    isMainPoleSelected() {
      return (
        this.isPoleFinanceSelected ||
        this.isPoleHoteSelected ||
        this.isPoleRhAdministratifSelected ||
        this.isPoleRhRecrutementSelected ||
        this.isPoleCommercialSelected ||
        this.isPoleVoyageurSelected ||
        this.isPoleDistributionRmSelected ||
        this.isPoleDistributionOnboardingSelected ||
        this.isPoleOperationsSelected ||
        this.isPoleComptabiliteMandanteEntranteSelected ||
        this.isPoleComptabiliteMandanteSortanteSelected
      );
    },
    isPoleForCategoryOrLabelSelected() {
      return (
        this.isPoleHoteSelected ||
        this.isPoleVoyageurSelected ||
        this.isPoleFinanceSelected ||
        this.isPoleComptabiliteMandanteEntranteSelected ||
        this.isPoleComptabiliteMandanteSortanteSelected ||
        this.isPoleRhAdministratifSelected ||
        this.isPoleRhRecrutementSelected ||
        this.isPoleCommercialSelected ||
        this.isPoleMarketcommSelected ||
        this.isPoleOperationsSelected
      );
    },

    // ATTRIBUTION POLES / COMPANY

    attributionPoles() {
      let list = [];
      if (this.isGas()) list.push({id: -1, display: 'Agence'});
      for (let pole of this.poles) list.push(pole);
      return list;
    },

    // COMPANY SELECTED

    isCompanySelected() {
      return !!this.ticket.companyId;
    },

    // INTERLOCUTEURS SELECTIONNES

    isInterlocuteurAccommodationSelected() {
      return this.ticket.interlocutorTypes.some((type) => type === 'ACCOMMODATION');
    },
    isInterlocuteurProprietaireSelected() {
      return this.ticket.interlocutorTypes.some((type) => type === 'PROPRIETAIRE');
    },
    isInterlocuteurProspectSelected() {
      return this.ticket.interlocutorTypes.some((type) => type === 'PROSPECT');
    },
    isInterlocuteurVoyageurSelected() {
      return this.ticket.interlocutorTypes.some((type) => type === 'VOYAGEUR');
    },

    // INPUT AVAILABLES

    isPeriodInputAvailable() {
      return this.isPoleDistributionRmSelected;
    },
    isCompanyInputAvailable() {
      return this.ticket.poleIds && this.ticket.poleIds.some((id) => id === -1);
    },
    isCategoryInputAvailable() {
      return this.categoriesForInput.length > 0;
    },
    categoriesForInput() {
      if (!this.categories) return [];
      if (!this.selectedPoleIds) return [];
      if (this.isMultiPoleSelected) return [];
      return this.categories.filter(
        (cat) => cat.poleIds && cat.poleIds.some((poleId) => poleId === this.selectedPoleIds[0]),
      );
    },
    isAccommodationInputAvailable() {
      if (!this.ticket) return false;
      if (!this.ticket.ownerId) return false;

      return (
        this.isPoleDistributionRmSelected ||
        this.isPoleDistributionOnboardingSelected ||
        this.isInterlocuteurAccommodationSelected
      );
    },
    isOwnerInputAvailable() {
      return this.isInterlocuteurAccommodationSelected || this.isInterlocuteurProprietaireSelected;
    },
    isProspectInputAvailable() {
      return this.isInterlocuteurProspectSelected;
    },
    isClientInputAvailable() {
      return this.isInterlocuteurVoyageurSelected;
    },
    isInterlocutorTypeInputAvailable() {
      return this.isMultiPoleSelected || this.isMainPoleSelected || this.isCompanySelected;
    },
    isLabelInputAvailable() {
      return (
        this.hasPoleSelected &&
        ((this.isPoleForCategoryOrLabelSelected && !this.isCategoryInputAvailable) || this.isPoleProduitSelected)
      );
    },

    // FIELDS REQUIRED

    isLabelRequired() {
      return this.isLabelInputAvailable;
    },
    isOwnerRequired() {
      return this.isOwnerInputAvailable;
    },
    isProspectRequired() {
      return this.isProspectInputAvailable;
    },
    isClientRequired() {
      return this.isClientInputAvailable;
    },
    isCompanyRequired() {
      return this.isCompanyInputAvailable;
    },
    isAccommodationRequired() {
      return this.isAccommodationInputAvailable;
    },
    isFilesRequired() {
      return false;
      //return this.isMultiPoleSelected || this.isMainPoleSelected;
    },
  },
};
</script>

<template>
  <AkSidebar
    :baseZIndex="9998"
    v-model:visible="createSidebarVisible"
    block-scroll
    position="right"
    :dismissable="true">
    <template #container>
      <div class="mb-4">
        <GaMessages ref="sidebarMessages" />
      </div>
      <div class="p-sidebar-header">
        <div class="p-sidebar-header-content">{{ this.fromHeader ? 'Demande d’assistance' : $t('ticket.add') }}</div>
        <button class="p-sidebar-close p-sidebar-icon p-link" type="button" @click="hide">
          <span
            class="p-sidebar-close-icon ga-icon ga-close_circle text-4xl text-gray hover:text-primary hover:bg-transparent" />
        </button>
      </div>
      <div class="p-sidebar-content">
        <perfect-scrollbar class="h-full flex flex-col gap-4">
          <AkFormSubmitted :submitted="submitted" class="flex flex-col gap-4" reference="form">
            <AkMultiSelect
              v-if="!this.fromHeader"
              v-model="ticket.poleIds"
              :loading="this.loadingPoles"
              :label="$t('ticket.attribution')"
              :options="attributionPoles"
              :validator="v$.ticket.poleIds"
              :disabled="source === 'header'"
              optionLabel="display"
              optionValue="id" />
            <AkDropdown
              v-if="isCompanyInputAvailable"
              v-model="ticket.companyId"
              :label="$t('employee.company')"
              :options="this.companies"
              :placeholder="$t('employee.company')"
              :validator="v$.ticket.companyId"
              option-label="name"
              option-value="id" />
            <AkDropdown
              v-model="ticket.priority"
              :label="$t('ticket.priority')"
              :options="ticketPriorityConst"
              :validator="v$.ticket.priority" />
            <AkDropdownGroup
              v-if="isCategoryInputAvailable"
              v-model="ticket.subCategoryId"
              :label="$t('ticket.category')"
              :options="categoriesForInput"
              :validator="v$.ticket.subCategoryId"
              option-group-children="subCategories"
              option-group-label="label"
              option-label="label"
              option-value="id" />
            <AkInputText
              v-if="isLabelInputAvailable"
              v-model="ticket.label"
              :label="$t('ticket.label')"
              :validator="v$.ticket.label" />
            <AkMultiSelect
              v-if="isInterlocutorTypeInputAvailable"
              v-model="ticket.interlocutorTypes"
              :label="$t('activity.interlocutorType')"
              :options="ticketInterlocutorTypeConst"
              :validator="v$.ticket.interlocutorTypes"
              optionLabel="label"
              optionValue="value" />

            <AkAutocomplete
              v-if="isOwnerInputAvailable"
              ref="ownerAutocomplete"
              v-model="ticket.ownerId"
              label="Hôte"
              option-value="id"
              option-label="display"
              :validator="v$.ticket.ownerId"
              :force-selection="false"
              :init-method="this.autocompleteOwnerById"
              :search-method="this.autocompleteOwner"
              :search-object="this.autocompleteOwnerCustomerByCompany()"
              :search-label="this.autocompleteOwnerQuery" />

            <AkDropdown
              v-if="isAccommodationInputAvailable"
              v-model="ticket.accommodationId"
              :label="$t('activity.accommodation')"
              :options="ownerAccommodations"
              :placeholder="$t('activity.accommodation')"
              :validator="v$.ticket.accommodationId"
              option-label="name"
              option-value="id" />

            <AkAutocomplete
              v-if="isProspectInputAvailable"
              ref="prospectAutocomplete"
              v-model="ticket.prospectId"
              label="Prospect"
              option-value="id"
              option-label="display"
              :validator="v$.ticket.prospectId"
              :force-selection="false"
              :init-method="this.autocompleteOwnerById"
              :search-method="this.autocompleteOwner"
              :search-object="this.autocompleteOwnerProspectByCompany()"
              :search-label="this.autocompleteOwnerQuery" />

            <AkAutocomplete
              v-if="isClientInputAvailable"
              ref="clientAutocomplete"
              v-model="ticket.clientId"
              label="Voyageur"
              option-value="id"
              option-label="display"
              :force-selection="false"
              :search-object="this.autocompleteClientByCompany()"
              :init-method="this.autocompleteClientById"
              :search-label="this.autocompleteClientQuery"
              :search-method="this.autocompleteClient" />

            <AkCalendar
              v-if="isPeriodInputAvailable"
              v-model="ticket.rmDateStart"
              :label="$t('visitor_tax.date_from')"
              :title="$t('visitor_tax.filter_date')"
              date-format="dd/mm/yy"
              selection-mode="single" />
            <AkCalendar
              v-if="isPeriodInputAvailable"
              v-model="ticket.rmDateEnd"
              :label="$t('visitor_tax.date_to')"
              :title="$t('visitor_tax.filter_date')"
              date-format="dd/mm/yy"
              selection-mode="single" />
            <AkInputTextArea
              v-model="ticket.description"
              :label="$t('ticket.description')"
              :validator="v$.ticket.description" />
            <AkFilesUpload
              ref="fileUpload"
              v-model="files"
              multiple
              :label="$t('ticket.files')"
              :validator="v$.files" />
            <p v-if="errors.length">
              <small v-for="(error, index) in errors" :key="index" style="color: red">
                {{ error }}
              </small>
            </p>

            <AkButtonFooterSidebar
              ref="footerSidebar"
              :validate-label="$t('create')"
              :cancel-label="$t('cancel')"
              @cancel="hide"
              @action="create" />
          </AkFormSubmitted>
        </perfect-scrollbar>
      </div>
    </template>
  </AkSidebar>
</template>
