<script>
import AkLabel from '@components/v2/general/AkLabel';
import InputSwitch from 'primevue/inputswitch';
import AkDialogConfirm from '@components/general/AkDialogConfirm';

export default {
  components: {AkDialogConfirm, AkLabel, InputSwitch},
  inject: {pDisabled: {default: null}},
  props: {
    modelValue: {
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
    className: {
      type: String,
      required: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    inline: {
      type: Boolean,
      required: false,
      default: false,
    },
    labelBefore: {
      type: Boolean,
      required: false,
      default: true,
    },
    displayLabel: {
      type: Boolean,
      required: false,
      default: true,
    },
    validationPopup: {
      type: Boolean,
      required: false,
      default: false,
    },
    messagePopup: {
      type: String,
      required: false,
      default: undefined,
    },
    titlePopup: {
      type: String,
      required: false,
      default: undefined,
    },
    nameOnPopup: {
      type: String,
      required: false,
      default: undefined,
    },
    widthPopup: {
      type: String,
      default: '600px',
    },
  },
  emits: ['update:modelValue', 'change'],
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        if (this.isDisabled) return;
        if (this.validationPopup) return;
        this.$emit('update:modelValue', val);
      },
    },
    buildPopupMessage() {
      if (this.messagePopup) return this.messagePopup;
      if (this.currentValue) return this.$t('confirm_disable', [this.nameOnPopup]);
      return this.$t('confirm_enable', [this.nameOnPopup]);
    },
    buildPopupTitle() {
      if (this.titlePopup) return this.titlePopup;
      if (this.currentValue) return this.$t('confirm_dialog');
      return this.$t('confirm_dialog');
    },
    isInline() {
      return this.inline;
    },
    getPlaceholder() {
      return this.placeholder ? this.placeholder : this.label;
    },
    isDisabled() {
      if (this.disabled !== undefined) return this.disabled;
      if (this.pDisabled !== undefined) return this.pDisabled;
      return false;
    },
  },
  methods: {
    change() {
      this.$emit('change');
    },
    openConfirmDialog() {
      if (this.isDisabled) return;
      this.$refs.confirmDialog.show();
    },
    onConfirmYes() {
      this.$emit('update:modelValue', !this.currentValue);
      this.change();
      this.$refs.confirmDialog.$refs.btnYes.init();
      this.$refs.confirmDialog.hide();
    },
  },
};
</script>

<template>
  <div :class="[className, {'form-group': !isInline, 'form-control transparent-switch': isInline}]">
    <div v-if="isInline" class="flex items-center gap-2">
      <AkLabel v-if="label && this.displayLabel && this.labelBefore === true" :inline="true">
        {{ label }}
      </AkLabel>
      <InputSwitch
        v-model="currentValue"
        :disabled="isDisabled"
        @change="validationPopup ? openConfirmDialog() : change()" />
      <AkLabel v-if="label && this.displayLabel && this.labelBefore === false" :inline="true">
        {{ label }}
      </AkLabel>
    </div>

    <div v-else>
      <AkLabel v-if="label && this.displayLabel">
        {{ label }}
      </AkLabel>
      <div class="flex flex-col flex-1">
        <InputSwitch
          v-model="currentValue"
          :disabled="isDisabled"
          @change="validationPopup ? openConfirmDialog() : change()" />
      </div>
    </div>
  </div>

  <AkDialogConfirm
    ref="confirmDialog"
    :width="widthPopup"
    :message="buildPopupMessage"
    :title="buildPopupTitle"
    @yes="onConfirmYes()" />
</template>
