<script>
import {ref} from 'vue';

/* COMPOSANTS */
import PButton from 'primevue/button';

/* MIXINS */
import loaderMixin from '@mixins/loaderMixin';
import messageMixin from '@mixins/messageMixin';
import sidebarMixin from '@mixins/sidebarMixin';
import utilsMixin from '@mixins/utilsMixin';

/* SERVICES */
import comptaAccountService from '@services/comptaAccountService';

export default {
  components: {
    PButton,
  },
  mixins: [loaderMixin, messageMixin, sidebarMixin, utilsMixin],
  metaInfo() {
    return {
      title: 'accounting.balance',
    };
  },
  data() {
    return {
      search: {},
      accounts: [],
      operations: [],
      credit: 0,
      debit: 0,
      solde: 0,
      loading: false,
    };
  },
  mounted() {
    this.initBalance();
    this.search.displayTerminatedMandats = false;
    this.search.displayReservations = false;
  },
  props: {},
  methods: {
    ref,
    initBalance() {
      this.loading = true;

      if (this.search.displayTerminatedMandats === undefined) {
        this.search.displayTerminatedMandats = false;
      }
      if (this.search.displayReservations === undefined) {
        this.search.displayReservations = false;
      }

      let p1 = comptaAccountService.balance(this.search);
      p1.then((data) => {
        this.accounts = data.accounts;
        this.credit = data.credit;
        this.debit = data.debit;
        this.solde = data.solde;

        for (let account of this.accounts) {
          account.balance = 0;
          account.balance = this.getBalance(account.credit, account.debit);
          account.formatDebit = this.formatDisplayNumber(account.debit, false);
        }
      });
      Promise.all([p1]).then(() => {
        this.loading = false;
      });
    },
    resetFilter() {
      this.search = {};
      this.toggleFilter('GaBalanceFilter', this.search, this.initBalance, this.resetFilter);
    },
    getBalance(debit, credit) {
      let num = credit + debit;
      return this.formatDisplayNumber(num, true);
    },
    formatDisplayNumber(num, isSolde) {
      if (!isSolde) {
        if (num < 0) {
          num *= -1;
        }
      }
      return Number.parseFloat(num).toFixed(2);
    },
    exportBalance() {
      this.showTotalLoaderWithAfter(this.$t('balance.balance_creation_in_progress'));
      let p2 = comptaAccountService.exportBalance(this.search);
      p2.then((data) => {
        this.hideLoader();
      });
    },
    exportPdfBalance() {
      this.showTotalLoaderWithAfter(this.$t('balance.balance_creation_in_progress'));
      comptaAccountService
        .exportPdfBalance(this.search)
        .catch((e) => {
          if (e.response) {
            if (e.response.status === 412) {
              this.addError(this.$t('balance.unknown_template'), true, true);
            }
          }
        })
        .finally(() => {
          this.hideLoader();
        });
    },
    rowClass(data) {
      return [{'hightlight-total': !data.num}];
    },
    openFilterPanel() {
      this.toggleFilter('GaBalanceFilter', this.search, this.initBalance, this.resetFilter);
    },
  },
  computed: {
    totalDebit() {
      return this.formatDisplayNumber(this.debit, false);
    },
    totalCredit() {
      return this.formatDisplayNumber(this.credit, false);
    },
    totalBalance() {
      return this.formatDisplayNumber(this.solde, true);
    },
    numberOfResults() {
      if (this.accounts) {
        if (this.accounts.length > 0) {
          return this.accounts.length - 1;
        }
      }
      return 0;
    },
  },
};
</script>

<template>
  <GaView :ref="ref" :title="$t('accounting.balance')">
    <template #action>
      <div class="d-flex justify-content-end" style="gap: 16px">
        <PButton
          :badge="this.countFilter(this.search)"
          badgeClass="p-badge-primary"
          class="btn btn-primary"
          label="Filtrer"
          @click="openFilterPanel()" />
        <button class="btn btn-primary" @click="exportPdfBalance()">
          {{ this.$t('payment.download_pdf') }}
        </button>
        <button class="btn btn-primary" @click="exportBalance()">
          {{ this.$t('payment.download') }}
        </button>
      </div>
    </template>

    <template #content>
      <div class="d-flex flex-column h-100">
        <div class="row flex-1">
          <div class="col-lg-12 h-100">
            <div class="card card-statistics h-100">
              <div class="card-body h-100">
                <div class="table-responsive h-100">
                  <DataTable
                    :always-show-paginator="false"
                    :paginator="false"
                    :rows="50"
                    :rows-per-page-options="[10, 20, 50]"
                    :value="this.accounts"
                    :loading="loading"
                    class="table separate"
                    current-page-report-template="{first}-{last}/{totalRecords}"
                    removable-sort
                    responsive-layout="scroll"
                    sort-field="position"
                    striped-rows
                    :row-class="rowClass"
                    scrollable
                    scrollHeight="100%"
                    :virtualScrollerOptions="{itemSize: 21}">
                    <template #empty>
                      {{ $t('accounting.empty') }}
                    </template>
                    <template #header>
                      <div class="d-flex align-items-center justify-content-start header-legend-result">
                        {{ this.$t('payment.number_results') }} {{ this.numberOfResults }}
                      </div>
                    </template>
                    <Column :header="this.$t('accounting.number')" field="num" :sortable="false">
                      <template #body="slotProps">
                        <router-link
                          v-if="slotProps.data.accountId !== null"
                          :to="{
                            name: 'accountingThirdPartyDetails',
                            params: {accountId: slotProps.data.accountId},
                            hash: '#balance',
                          }">
                          {{ slotProps.data.num }}
                        </router-link>
                        <span v-else>
                          {{ slotProps.data.num }}
                        </span>
                      </template>
                    </Column>
                    <Column :header="this.$t('accounting.label')" field="label" :sortable="false"> </Column>
                    <Column
                      :header="this.$t('accounting.debit')"
                      class="text-right"
                      field="formatDebit"
                      :sortable="false">
                      <template #body="slotProps">
                        {{ formatCurrencyEUR(Math.abs(slotProps.data.debit)) }}
                      </template>
                    </Column>
                    <Column :header="this.$t('accounting.credit')" class="text-right" field="credit" :sortable="false">
                      <template #body="slotProps">
                        {{ formatCurrencyEUR(Math.abs(slotProps.data.credit)) }}
                      </template>
                    </Column>
                    <Column :header="this.$t('account.balance')" class="text-right" :sortable="false" field="balance">
                      <template #body="slotProps">
                        {{ formatCurrencyEUR(slotProps.data.balance) }}
                      </template>
                    </Column>
                    <template #footer>
                      <div class="d-flex align-items-center justify-content-end" style="column-gap: 40px">
                        <span
                          ><span class="poppins-medium">{{ this.$t('accounting.debit') }} :</span>
                          {{ formatCurrencyEUR(this.totalDebit) }}</span
                        >
                        <span
                          ><span class="poppins-medium">{{ this.$t('accounting.credit') }} :</span>
                          {{ formatCurrencyEUR(this.totalCredit) }}</span
                        >
                        <span
                          ><span class="poppins-medium">{{ this.$t('account.balance') }} :</span>
                          {{ formatCurrencyEUR(this.totalBalance) }}</span
                        >
                      </div>
                    </template>
                  </DataTable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </GaView>
</template>

<style>
.p-datatable.separate table {
  border-collapse: separate !important;
}
div .p-column-header-content {
  color: #9e9e9e;
}
.p-datatable-scrollable.p-datatable-responsive-scroll {
  height: 100%;
}
.p-datatable-scrollable.p-datatable-responsive-scroll .p-datatable-wrapper {
  height: calc(100% - 80px);
}

.p-datatable .p-datatable-thead > tr > th {
  border: none !important;
}
</style>
